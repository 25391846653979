<!--
 * @Description: 报价详情 采购商
 * @Author: zhang zhen
 * @Date: 2023-07-27 15:33:45
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-10-02 13:28:58
 * @FilePath: /page-sass/src/views/requirement/requirementList/quotePriceInfo.vue
-->
<template>
  <div class="quotePriceInfo">
    <div class="header">
      <div class="header-title">{{ baseInfo.purchaseTitle || '' }}</div>
      <div class="header-subtitle">
        <span class="label">需求编号：{{ baseInfo.purchaseNo || '' }}</span>
        <a class="copy" @click="handleCopy">复制</a>
      </div>
    </div>
    <a-tabs v-model="activeKey">
      <a-tab-pane key="1" tab="报价企业" force-render>
        <div class="info-item">
          <div class="avatar">
            <img :src="coverImage || '/company.png'" alt="" />
          </div>
          <div class="info-view">
            <div class="main-title">
              <span class="label">{{ businessName || '-' }}</span>
              <img src="/passTag.png" alt="" class="tag" />
              <a class="view-info" @click="toTargetPage">查看更多 ></a>
            </div>
            <div class="tags-list">
              <div class="tags-list-item" v-for="row in leftTagList" :key="row">
                <span>
                  <a-tooltip slot="suffix" :title="row">
                    {{ row }}
                  </a-tooltip>
                </span>
              </div>
            </div>
            <div class="info-box" :class="!showMore && 'hidden'" id="contentBoxText">
              {{ description }}
            </div>
            <a-button ghost type="primary" @click="handleEmitChat" class="action-part">
              <div class="btn-inner">
                <img src="/message-3-line.png" alt="" class="icon" />
                <span class="text">咨询TA</span>
              </div>
            </a-button>
          </div>
          <template v-if="needAction">
            <div class="option-btn" v-if="!showMore" @click="showMore = !showMore">
              <span>展开</span>
              <img src="/pic_down.png" alt="" class="icon" />
            </div>
            <div class="option-btn" v-else @click="showMore = !showMore">
              <span>收起</span>
              <img src="/pic_up.png" alt="" class="icon" />
            </div>
          </template>
        </div>
        <h2 class="RequirementConfirmation-title"><span class="title">我的询价</span></h2>
        <div>
          <div>
            <a-table :columns="columns" :pagination="false" :data-source="purchaserList" bordered :class="'table1'">
              <template slot="skuName" slot-scope="text, record, index">
                <div class="td">{{ text }}</div>
              </template>
              <template slot="wrapTypeDictName" slot-scope="text, record, index">
                <div class="td">{{ text }}</div>
              </template>

              <template slot="quotePrice" slot-scope="text, record, index">
                <div v-if="record.enquiryType==1" class="td">
                  <span v-if="record.skuPrice"> {{ record.skuPrice }}元/&nbsp;{{ record.skuUnitDictName }}</span>
                  <span v-else>由供应商报价</span>
                </div>
                <div v-if="record.enquiryType==2" v-for="(item ,index) in record.enquiryLadders" class="td1">
                  <span v-if="item.unitPrice"> {{ item.unitPrice }}元/&nbsp;{{ record.skuUnitDictName }}</span>
                  <span v-else>由供应商报价</span>
                </div>
                <div v-if="record.enquiryType==3">
                  <span v-if="record.enquiryOrigin.price"> {{ record.enquiryOrigin.price
                    }}元/&nbsp;{{ record.skuUnitDictName }}（含：{{ record.enquiryOrigin.compose.replace(/,/g, '+')
                    }}）</span>
                  <span v-else>由供应商报价</span>
                </div>
              </template>
              <template slot="skuQuantity" slot-scope="text, record, index">
                <div v-if="record.enquiryType==1||record.enquiryType==3">{{ text }}{{ record.skuUnitDictName }}
                </div>
                <div v-if="record.enquiryType==2" v-for="(item ,index) in record.enquiryLadders" class="td1">
                   <span style="display: flex">
             <span v-if="index==0 && record.enquiryLadders[index].intervalNumber&& record.enquiryLadders.length>1">
                              {{ record.enquiryLadders[index].intervalNumber
               }}{{ record.skuUnitDictName
               }} &nbsp;&nbsp;~&nbsp;&nbsp;{{ record.enquiryLadders[index + 1].intervalNumber - 1
               }}{{ record.skuUnitDictName }}
                            </span>
                    <span
                      v-if="index==1 && record.enquiryLadders[index].intervalNumber && record.enquiryLadders.length==2">
                              ≥&nbsp;&nbsp;{{ record.enquiryLadders[index].intervalNumber }}{{ record.skuUnitDictName }}
                            </span>
                    <span
                      v-else-if="index>=1 && index!=record.enquiryLadders.length-1 && record.enquiryLadders[index].intervalNumber ">
                              {{ record.enquiryLadders[index].intervalNumber
                      }}{{ record.skuUnitDictName
                      }}&nbsp;&nbsp;~&nbsp;&nbsp;{{ record.enquiryLadders[index + 1].intervalNumber - 1
                      }}{{ record.skuUnitDictName }}
                            </span>
                    <span
                      v-else-if="index==record.enquiryLadders.length-1 && record.enquiryLadders[index].intervalNumber">
                              ≥&nbsp;&nbsp;{{ record.enquiryLadders[index].intervalNumber }}{{ record.skuUnitDictName }}
                            </span>
                    <span v-else></span>
                   </span>
                </div>
              </template>
              <template slot="skuUnit" slot-scope="text, record, index">
                <div>
                  <div v-if="record.enquiryType==1" class="td">
                    {{ multiply((record.skuPrice || 0), (record.skuQuantity || 0))
                    }}元
                  </div>
                  <div v-else-if="record.enquiryType==3" class="td">
                    {{ multiply((record.enquiryOrigin.price || 0), (record.skuQuantity || 0)) }}元
                  </div>
                  <!--                  v-for="(item ,index) in record.enquiryLadders"-->
                  <div v-else-if="record.enquiryType==2">-
                  </div>
                </div>
              </template>
            </a-table>
          </div>
        </div>

        <h2 class="RequirementConfirmation-title"><span class="title">供应商报价</span></h2>
        <div>
          <a-table :columns="columns" :pagination="false" :data-source="quoteItems" bordered :class="'table1'">
            <template slot="skuName" slot-scope="text, record, index">
              <div class="td">{{ text }}</div>
            </template>
            <template slot="wrapTypeDictName" slot-scope="text, record, index">
              <div class="td">{{ text }}</div>
            </template>

            <template slot="quotePrice" slot-scope="text, record, index">
              <div v-if="record.quoteMode==1" class="td">
                <span v-if="record.quotePrice"> {{ record.quotePrice }}元/&nbsp;{{ record.skuUnitDictName }}</span>
                <span v-else>由供应商报价</span>
              </div>
              <div v-if="record.quoteMode==2" v-for="(item ,index) in record.purchaseEnquiryLadder" class="td1">
                <span v-if="item.unitPrice"> {{ item.unitPrice }}元/&nbsp;{{ record.skuUnitDictName }}</span>
                <span v-else>由供应商报价</span>
              </div>
              <div v-if="record.quoteMode==3">
                  <span v-if="record.purchaseEnquiryOrigin[0].price"> {{ record.purchaseEnquiryOrigin[0].price
                    }}元/&nbsp;{{ record.skuUnitDictName }}（含：{{ record.purchaseEnquiryOrigin[0].composeName
                    }}）</span>
                <span v-else>由供应商报价</span>
              </div>
            </template>
            <template slot="skuQuantity" slot-scope="text, record, index">
              <div v-if="record.quoteMode==1">{{ text }}{{ record.skuUnitDictName }}
              </div>
              <div v-if="record.quoteMode==3">{{ text }}{{ record.skuUnitDictName }}
              </div>
              <div v-if="record.quoteMode==2" v-for="(item ,index) in record.purchaseEnquiryLadder" class="td1">
                   <span style="display: flex">
             <span
               v-if="index==0 && record.purchaseEnquiryLadder[index].intervalNumber&& record.purchaseEnquiryLadder.length>1">
                              {{ record.purchaseEnquiryLadder[index].intervalNumber
               }}{{ record.skuUnitDictName
               }} &nbsp;&nbsp;~&nbsp;&nbsp;{{ record.purchaseEnquiryLadder[index + 1].intervalNumber - 1
               }}{{ record.skuUnitDictName }}
                            </span>
                    <span
                      v-if="index==1 && record.purchaseEnquiryLadder[index].intervalNumber && record.purchaseEnquiryLadder.length==2">
                              ≥&nbsp;&nbsp;{{ record.purchaseEnquiryLadder[index].intervalNumber
                      }}{{ record.skuUnitDictName }}
                            </span>
                    <span
                      v-else-if="index>=1 && index!=record.purchaseEnquiryLadder.length-1 && record.purchaseEnquiryLadder[index].intervalNumber ">
                              {{ record.purchaseEnquiryLadder[index].intervalNumber
                      }}{{ record.skuUnitDictName
                      }}&nbsp;&nbsp;~&nbsp;&nbsp;{{ record.purchaseEnquiryLadder[index + 1].intervalNumber - 1
                      }}{{ record.skuUnitDictName }}
                            </span>
                    <span
                      v-else-if="index==record.purchaseEnquiryLadder.length-1 && record.purchaseEnquiryLadder[index].intervalNumber">
                              ≥&nbsp;&nbsp;{{ record.purchaseEnquiryLadder[index].intervalNumber
                      }}{{ record.skuUnitDictName }}
                            </span>
                    <span v-else></span>
                   </span>
              </div>
            </template>
            <template slot="skuUnit" slot-scope="text, record, index">
              <div>
                <div v-if="record.quoteMode==1" class="td">
                  {{ multiply((record.quotePrice || 0), (record.skuQuantity || 0))
                  }}（元）
                </div>
                <div v-else-if="record.quoteMode==3" class="td">
                  {{ multiply((record.purchaseEnquiryOrigin[0].price || 0), (record.skuQuantity || 0))
                  }}（元）
                </div>
                <!--                v-for="(item ,index) in record.purchaseEnquiryLadder"-->
                <div v-else-if="record.quoteMode==2">-
                </div>
              </div>
            </template>
          </a-table>
          <!--          <template v-for="(item, index) in quoteItems">-->
          <!--            <div>-->
          <!--              <a-table :columns="columns" :pagination="false" :data-source="item.quoteHistoryItemVos" bordered-->
          <!--                       :class="'table1'">-->
          <!--                <template slot="skuName" slot-scope="text, record, index">-->
          <!--                  <div class="td">{{ text }}</div>-->
          <!--                </template>-->
          <!--                <template slot="wrapTypeDictName" slot-scope="text, record, index">-->
          <!--                  <div class="td">{{ text }}</div>-->
          <!--                </template>-->
          <!--                <template slot="quotePrice" slot-scope="text, record, index">-->
          <!--                  <div v-if="record.quoteMode==1" class="td">-->
          <!--                    <span v-if="record.quotePrice"> {{ record.quotePrice }}（元）/&nbsp;{{ record.skuUnitDictName-->
          <!--                      }}</span>-->
          <!--                    <span v-else>由供应商报价</span>-->
          <!--                  </div>-->
          <!--                  <div v-if="record.quoteMode==2" v-for="(item ,index) in record.purchaseEnquiryLadder" class="td1">-->
          <!--                    <span v-if="item.unitPrice"> {{ item.unitPrice }}（元）/&nbsp;{{ record.skuUnitDictName }}</span>-->
          <!--                    <span v-else>由供应商报价</span>-->
          <!--                  </div>-->
          <!--                  <div v-if="record.quoteMode==3" v-for=" item in record.purchaseEnquiryOrigin	">-->
          <!--                  <span v-if="item.price">-->
          <!--                    {{ item.price-->
          <!--                    }}（元）/&nbsp;{{ record.skuUnitDictName }}（含：-->
          <!--                    <span v-for="(i,index) in JSON.parse(item.compose)">-->
          <!--                      <span v-if="index <(JSON.parse(item.compose).length-1)">{{ i.name }}+</span>-->
          <!--                        <span v-else>{{ i.name }}</span></span>-->
          <!--                    )-->
          <!--                  </span>-->
          <!--                    <span v-else>由供应商报价</span>-->
          <!--                  </div>-->
          <!--                </template>-->
          <!--                <template slot="skuQuantity" slot-scope="text, record, index">-->
          <!--                  <div v-if="record.quoteMode==1 ||record.quoteMode==3">{{ text }}{{ record.skuUnitDictName }}-->
          <!--                  </div>-->
          <!--                  <div v-if="record.quoteMode==2" v-for="(item ,index) in record.purchaseEnquiryLadder" class="td1">-->
          <!--  <span style="display: flex ;text-align: center">-->
          <!--             <span v-if="index==0 && record.purchaseEnquiryLadder[index].intervalNumber&& record.purchaseEnquiryLadder.length>1">-->
          <!--                              {{ record.purchaseEnquiryLadder[index].intervalNumber-->
          <!--               }}{{ record.skuUnitDictName-->
          <!--               }} &nbsp;&nbsp;~&nbsp;&nbsp;{{ record.purchaseEnquiryLadder[index + 1].intervalNumber - 1-->
          <!--               }}{{ record.skuUnitDictName }}-->
          <!--                            </span>-->
          <!--                    <span-->
          <!--                      v-if="index==1 && record.purchaseEnquiryLadder[index].purchaseEnquiryLadder && record.purchaseEnquiryLadder.length==2">-->
          <!--                              ≥&nbsp;&nbsp;{{ record.purchaseEnquiryLadder[index].intervalNumber }}{{ record.skuUnitDictName }}-->
          <!--                            </span>-->
          <!--                    <span-->
          <!--                      v-else-if="index>=1 && index!=record.purchaseEnquiryLadder.length-1 && record.purchaseEnquiryLadder[index].intervalNumber ">-->
          <!--                              {{ record.purchaseEnquiryLadder[index].intervalNumber-->
          <!--                      }}{{ record.skuUnitDictName-->
          <!--                      }}&nbsp;&nbsp;~&nbsp;&nbsp;{{ record.purchaseEnquiryLadder[index + 1].intervalNumber - 1-->
          <!--                      }}{{ record.skuUnitDictName }}-->
          <!--                            </span>-->
          <!--                    <span-->
          <!--                      v-else-if="index==record.purchaseEnquiryLadder.length-1 && record.purchaseEnquiryLadder[index].intervalNumber">-->
          <!--                              ≥&nbsp;&nbsp;{{ record.purchaseEnquiryLadder[index].intervalNumber }}{{ record.skuUnitDictName }}-->
          <!--                            </span>-->
          <!--                    <span v-else></span>-->
          <!--                   </span>-->
          <!--                  </div>-->
          <!--                </template>-->
          <!--                <template slot="skuUnit" slot-scope="text, record, index">-->
          <!--                  <div v-if="record.quoteMode==1">-->
          <!--                    {{ multiply((record.quotePrice || 0), (record.skuQuantity || 0)) }}-->
          <!--                  </div>-->
          <!--                  <div v-if="record.quoteMode==3" v-for="(item,index) in record.purchaseEnquiryOrigin">-->
          <!--                    {{ record.totalPrice }}-->
          <!--                  </div>-->
          <!--                  <div v-if="record.quoteMode==2" v-for="(item ,index) in record.purchaseEnquiryLadder" class="td1">- -->
          <!--                  </div>-->

          <!--                </template>-->
          <!--              </a-table>-->
          <!--            </div>-->
          <!--          </template>-->
        </div>

        <!--        <div style="width: 1073px">-->
        <!--          <template v-for="(item, index) in quoteList.slice(0,!quoteItem.expand?1:quoteList.length)">-->
        <!--            <splitLine v-if="item.quoteDesc=='最终报价'" :label="`${item.quoteDesc}`" iconPath="/last-quote.png" />-->
        <!--            <splitLine v-else :label="`${item.quoteDesc}`" iconPath="/quotePrice.png" />-->
        <!--            <PriceCooperationTable-->
        <!--              :class="index != 0 && 'less-info'"-->
        <!--              :dataSource="item.quoteHistoryItemVos"-->
        <!--              :total="item.allTotal"-->
        <!--              :purchaserInquiryMode="purchaserInquiryMode"-->
        <!--              :quoteStatus="item.quoteStatus"-->
        <!--              :quoteDesc="item.quoteDesc"-->
        <!--              :quoteIndex="index"-->
        <!--            />-->
        <!--          </template>-->
        <!--          <div class="more-history" @click="onExpand(quoteItem)" v-if="quoteList.length>1 && quoteList!=undefined">-->
        <!--            {{ quoteItem.expand ? '收起历史报价信息' : '展开历史报价信息' }}-->
        <!--            <img v-if="!quoteItem.expand" src="@/assets/expand-history.png"-->
        <!--                 style="width: 12px;height: 12px;margin-left: 2px;">-->
        <!--            <img v-if="quoteItem.expand" src="@/assets/close-history.png"-->
        <!--                 style="width: 12px;height: 12px;margin-left: 2px;">-->

        <!--          </div>-->
        <!--        </div>-->
        <div class="action-button-area">
          <a-button @click="handleBack">返回</a-button>
          <a-button type="primary" @click="handleOk" v-if="isDetail == '2'">确定合作</a-button>
          <!--          <a-button type="primary" @click="showInfo = true" v-if="isDetail == '2'">确定合作</a-button>-->
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="需求详情" force-render>
        <RequirementInfo
          :needSteps="false"
          :showOrderInfo="false"
          :purchaseId="purchaseId"
          :needLoadNow="true"
          @showInfo="handleCallbackData"
        />
      </a-tab-pane>
    </a-tabs>


    <a-modal
      title="温馨提示"
      :width="600"
      :visible="showInfo"
      okText="确认"
      centered
      @ok="chooseHim"
      @cancel="showInfo = false"
    >
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>点击“确认”即表示确认合作关系，合作关系成功建立后，请在“订单管理”操作合作流程。</span>
      </div>
    </a-modal>
    <a-modal
      title="温馨提示"
      :width="600"
      :visible="showSubmit"
      okText="确认"
      centered
      @ok="chooseCooperate"
      @cancel="showSubmit = false"
    >
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>点击“确认”即表示确定合作数量。</span>
      </div>
    </a-modal>
    <PurchaserCooperation ref="purchaserCooperation"></PurchaserCooperation>
  </div>
</template>

<script>
import { postAction, getAction, postQueryAction } from '@/api/manage'
import { mapGetters } from 'vuex'
import RequirementInfo from '@/components/plugins/requirementInfo.vue'
import splitLine from '@/components/plugins/splitLine.vue'
import QuotePriceTable from '@/components/plugins/quotePriceTable.vue'
import PriceCooperationTable from '@/components/plugins/priceCooperationTable.vue'
import ChatMixin from '@/mixins/chatMixins'
import PurchaserCooperation from '@/views/requirement/requirementList/modules/purchaserCooperation.vue'

export default {
  name: 'quotePriceDetails',
  mixins: [ChatMixin],
  components: {
    RequirementInfo,
    splitLine,
    QuotePriceTable,
    PurchaserCooperation,
    PriceCooperationTable
  },
  data() {
    return {
      showMore: false,
      activeKey: '1',
      showInfo: false,
      showSubmit: false,
      showStep: true,
      purchaseId: null,
      baseInfo: {},
      purchaseItem: [],
      formInfo: {},
      isDetail: '2',
      layoutInfo: {
        wrapperCol: { span: 12 },
        labelCol: { span: 12 }
      },
      form: {}, // 其他信息字段
      formModel: 2,
      quoteId: null,
      totalAmount: 0,
      quoteFileList: [],
      fileList: [],
      showFile: true,
      quoteList: [],
      businessId: null,
      leftTagList: [],
      businessName: null,
      coverImage: null,
      description: null,
      needAction: false,
      purchaserInquiryMode: 1,
      quoteItem: { expand: false },
      quoteStatus: null,
      roleType: null,
      purchaserList: [],
      quoteItems: [],
      columns: [
        {
          title: '报价需求',
          dataIndex: 'skuName',
          scopedSlots: { customRender: 'skuName' },
          width: 210,
          align: 'center'
        },
        {
          title: '包装材料',
          dataIndex: 'wrapTypeDictName',
          scopedSlots: { customRender: 'wrapTypeDictName' },
          width: 200,
          align: 'center'
        },
        {
          title: '报价数量',
          dataIndex: 'skuQuantity',
          width: 200,
          scopedSlots: { customRender: 'skuQuantity' },
          align: 'center'
        },
        {
          title: '报价单价',
          dataIndex: 'quotePrice',
          scopedSlots: { customRender: 'quotePrice', title: 'purchaseEnquiryLadder' },
          width: 250,
          align: 'center'
        },
        {
          title: '合计',
          dataIndex: 'skuUnit',
          scopedSlots: { customRender: 'skuUnit' },
          align: 'center',
          width: 177
        }
      ]
    }
  },
  created() {
    const { purchaseId, quoteId, isDetail, businessId } = this.$route.query
    isDetail && (this.isDetail = isDetail)
    if (purchaseId) {
      this.purchaseId = purchaseId
      this.quoteId = quoteId
      this.businessId = businessId
      this.handlePurListInfo()
      this.handleGetInfo()
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleEmitChat() {
      this.handleChat({
        businessName: this.businessName,
        userId: this.businessId
      })
    },
    handleBack() {
      this.$router.go(-1)
    },
    checkTextLength() {
      const contentBox = document.getElementById('contentBoxText')
      // 假设两行的高度大约为 line-height * 2，但这里可能需要根据你的具体样式进行调整
      const estimatedTwoLinesHeight = 44 // 这个值需要根据你的字体大小和行高来设置
      console.log(contentBox.clientWidth)
      // 创建一个与contentBox相同样式的临时元素，但不包含-webkit-line-clamp
      const tempElement = document.createElement('div')
      tempElement.style.cssText =
        'width: ' +
        contentBox.clientWidth +
        'px' +
        '; line-height: ' +
        22.4 +
        'px' +
        '; font-size: ' +
        window.getComputedStyle(contentBox).fontSize +
        '; position: absolute; visibility: hidden;display: inline-block;'
      tempElement.textContent = contentBox.textContent
      document.body.appendChild(tempElement)

      // 获取临时元素的高度（这里假设内容不会换行，因此使用scrollWidth作为高度的一个粗略估计）
      // 注意：这种方法在内容可能换行时不准确，但在这个案例中，我们假设内容不会超过容器的宽度
      const actualHeight = tempElement.scrollHeight

      document.body.removeChild(tempElement)

      // 根据实际高度和预估的两行高度来决定是否显示“查看更多”
      console.log(actualHeight)
      if (actualHeight > estimatedTwoLinesHeight) {
        this.needAction = true
      } else {
        this.needAction = false
      }
    },
    handleQueryByBusinessIdAndRoleType() {
      getAction('/v2/business/basic/info/queryByBusinessIdAndRoleType', {
        businessId: this.businessId
      }).then((res) => {
        const { success, data } = res
        if (success) {
          let queryByBusiness = data
          console.log(queryByBusiness)
          console.log(queryByBusiness.roleType)
          this.roleType = queryByBusiness.roleType

          if (this.roleType == 0) {
            this.handleCallbackBusinessData()
            console.log('this.roleType4154')
          } else if (this.roleType == 4) {
            this.handleCallbackDsignerData()
          } else if (this.roleType == 1) {
            this.handleCallbackServiceData()
          }
        }
      })
    },
    //查询供应商
    handleCallbackBusinessData() {
      getAction('/v2/business/supplier/info/queryByBusinessId', {
        businessId: this.businessId
      }).then((res) => {
        const { success, data } = res
        if (success) {
          const {
            // yearTurnoverDictName,
            minOrderCountDictName,
            sampleTestDictName,
            authMethodDictName,
            printingMethodDictName,
            productPackageDictName,
            purchasingMethodDictName
          } = data
          this.businessName = data?.businessName
          this.description = data?.description
          this.coverImage = data?.companyLogo
          this.$nextTick(() => {
            const that = this
            // 在页面加载完成后调用该函数
            document.addEventListener('DOMContentLoaded', that.checkTextLength())
          })
          let sampleTestDictNameList = [],
            authMethodDictNameList = [],
            printingMethodDictNameList = []
          if (sampleTestDictName) {
            sampleTestDictNameList = sampleTestDictName.split(',').map((item) => `支持${ item }`)
          }
          if (authMethodDictName) {
            authMethodDictNameList = authMethodDictName.split(',').map((item) => `支持${ item }`)
          }
          if (printingMethodDictName) {
            printingMethodDictNameList = printingMethodDictName.split(',').map((item) => `支持${ item }`)
          }
          let tagList = [
            '最小下单量：' + minOrderCountDictName,
            ...sampleTestDictNameList,
            ...authMethodDictNameList,
            ...printingMethodDictNameList,
            productPackageDictName,
            purchasingMethodDictName
          ].filter((item) => !!item)
          this.leftTagList = tagList.slice(0, 5)
        }
      })
    },
    //查询服务商
    handleCallbackServiceData() {
      getAction('/v2/business/service/provider/queryByBusinessId', {
        businessId: this.businessId
      }).then((res) => {
        const { success, data } = res
        if (success) {
          const {
            // yearTurnoverDictName,
            minOrderCountDictName,
            sampleTestDictName,
            authMethodDictName,
            printingMethodDictName,
            productPackageDictName,
            purchasingMethodDictName
          } = data
          this.businessName = data?.companyName
          this.description = data?.description
          this.coverImage = data?.companyLogo
          this.$nextTick(() => {
            const that = this
            // 在页面加载完成后调用该函数
            document.addEventListener('DOMContentLoaded', that.checkTextLength())
          })
          let sampleTestDictNameList = [],
            authMethodDictNameList = [],
            printingMethodDictNameList = []
          if (sampleTestDictName) {
            sampleTestDictNameList = sampleTestDictName.split(',').map((item) => `支持${ item }`)
          }
          if (authMethodDictName) {
            authMethodDictNameList = authMethodDictName.split(',').map((item) => `支持${ item }`)
          }
          if (printingMethodDictName) {
            printingMethodDictNameList = printingMethodDictName.split(',').map((item) => `支持${ item }`)
          }
          let tagList = [
            '最小下单量：' + minOrderCountDictName,
            ...sampleTestDictNameList,
            ...authMethodDictNameList,
            ...printingMethodDictNameList,
            productPackageDictName,
            purchasingMethodDictName
          ].filter((item) => !!item)
          this.leftTagList = tagList.slice(0, 5)
        }
      })
    },
    //查询设计师
    handleCallbackDsignerData() {
      getAction('/v2/business/designer/info/queryByBusinessId', {
        businessId: this.businessId
      }).then((res) => {
        const { success, data } = res
        if (success) {
          const {
            // yearTurnoverDictName,
            minOrderCountDictName,
            sampleTestDictName,
            authMethodDictName,
            printingMethodDictName,
            productPackageDictName,
            purchasingMethodDictName
          } = data
          this.businessName = data?.companyName
          this.description = data?.description
          this.coverImage = data?.companyLogo
          this.$nextTick(() => {
            const that = this
            // 在页面加载完成后调用该函数
            document.addEventListener('DOMContentLoaded', that.checkTextLength())
          })
          let sampleTestDictNameList = [],
            authMethodDictNameList = [],
            printingMethodDictNameList = []
          if (sampleTestDictName) {
            sampleTestDictNameList = sampleTestDictName.split(',').map((item) => `支持${ item }`)
          }
          if (authMethodDictName) {
            authMethodDictNameList = authMethodDictName.split(',').map((item) => `支持${ item }`)
          }
          if (printingMethodDictName) {
            printingMethodDictNameList = printingMethodDictName.split(',').map((item) => `支持${ item }`)
          }
          let tagList = [
            '最小下单量：' + minOrderCountDictName,
            ...sampleTestDictNameList,
            ...authMethodDictNameList,
            ...printingMethodDictNameList,
            productPackageDictName,
            purchasingMethodDictName
          ].filter((item) => !!item)
          this.leftTagList = tagList.slice(0, 5)
        }
      })
    },
    handleGetInfo() {
      if (this.quoteId && this.quoteId != 'null') {
        getAction(`/quote/get/${ this.quoteId }`).then(res => {
          const { success, data } = res
          if (success) {
            const { quoteItems, status } = data
            if (quoteItems) {
              quoteItems.map(item => {
                if (item.quoteMode == 3 && item.purchaseEnquiryOrigin && item.purchaseEnquiryOrigin.length > 0) {
                  console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa1111111')
                  console.log(JSON.parse(item.purchaseEnquiryOrigin[0].compose))
                  let composeName = ''
                  JSON.parse(item.purchaseEnquiryOrigin[0].compose).map((item1) => {
                    composeName += (item1.name + item1.unitPrice) + '元+'
                  })
                  composeName = composeName.substring(0, composeName.length - 1)
                  item.purchaseEnquiryOrigin[0].composeName = composeName
                }

              })
            }
            this.quoteStatus = status
            this.quoteItems = quoteItems
          }
        })
      }
      // 加载报价信息
      postAction('/quote/history', {
        businessId: this.businessId,
        purchaseId: this.purchaseId,
        status: 0
      }).then((res) => {
        const { success, data } = res
        if (success) {
          // this.quoteList = data && data.reverse() || []
          this.quoteList = data.slice(0, 1) || []
          this.quoteList.map(item => {
            item.quoteStatus = this.quoteStatus
            item.quoteHistoryItemVos = item.quoteHistoryItemVos.slice(0, 1)
          })
          // this.quoteList = data || []
        }
      })
      //获取角色
      this.handleQueryByBusinessIdAndRoleType()

    },
    /* 复制单号 */
    handleCopy() {
      const _input = document.createElement('input') // 直接构建input
      _input.value = this.baseInfo.purchaseNo // 设置内容
      document.body.appendChild(_input) // 添加临时实例
      _input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      document.body.removeChild(_input) // 删除临时实例
      this.$message.success('复制成功')
    },
    handleCallbackData(e) {
      this.baseInfo = { ...e }
    },
    chooseHim() {
      this.showInfo = false
      // 接口
      postAction('/quote/confirm', {
        purchaseId: this.purchaseId,
        quoteId: this.quoteId
      }).then((res) => {
        const { success, message } = res
        if (success) {
          this.$message.success(message)
          this.$router.replace('/requirement/requirementList')
        } else {
          this.$message.warning(message)
        }
      })
    },
    chooseCooperate() {
      this.showSubmit = false
      this.quoteItems.map(item => {
        if (item.quoteSkuQuantity != null && item.quoteSkuQuantity != undefined) {
          item.skuQuantity = item.quoteSkuQuantity
        }
        if (item.purchaseEnquiryOrigin != null && item.purchaseEnquiryOrigin != undefined) {
          item.purchaseEnquiryOrigin.map(item1 => {
            item1.number = item.skuQuantity
          })
        }
        item.skuPrice = item.quotePrice
      })
      const payload = {}
      payload.purchaseId = this.purchaseId
      payload.businessId = this.businessId
      payload.quoteId = this.quoteId
      payload.quoteItems = this.quoteItems

      // 接口
      postAction('/quote/confirm', payload).then((res) => {
        const { success, message } = res
        if (success) {
          this.$message.success(message)
          this.$router.replace('/requirement/requirementList')
        } else {
          this.$message.warning(message)
        }
      })
    },
    // 取消
    handleCancel() {
      this.$router.go(-1)
    },
    handlePurListInfo() {
      postAction(`/v2/purchases/showPurList/${ this.purchaseId }`).then((res) => {
        const { success, data, message } = res
        if (success) {
          const { itemList } = data
          !itemList || itemList.length == 0 && (this.formModel = 1)
          itemList.map(i => {
            this.purchaserInquiryMode = i.enquiryType
          })
          this.purchaserList = itemList

        }
      })
    }, onExpand(item) {
      this.quoteItem.expand = !item.expand
    },
    handleOk() {
      // this.$refs.purchaserCooperation.handleOpen(this.quoteItems, this.purchaseId, this.quoteId)
      this.showSubmit = true
    },
    toTargetPage() {
      if (this.roleType == 0) {
        this.$router.push(
          `/companyInfoPage?id=` + this.businessId
        )
      } else if (this.roleType == 4) {
        this.$router.push(
          `/caseDetailPage?id=` + this.businessId
        )
      } else if (this.roleType == 1) {
        this.$router.push(
          `/serviceInfoPage?id=` + this.businessId
        )
      }
    }, multiply(a, b) {
      const precision = this.getPrecision(a) + this.getPrecision(b)
      const multiplier = Math.pow(10, precision)
      return (Math.round(a * multiplier) * Math.round(b * multiplier)) / (multiplier * multiplier)
    }, getPrecision(num) {
      const str = String(num)
      const decimalIndex = str.indexOf('.')
      return decimalIndex === -1 ? 0 : str.length - decimalIndex - 1
    }
  }
}
</script>

<style lang="less" scoped>
.quotePriceInfo {
  padding: 24px 24px 40px;
  box-sizing: border-box;
  background: #fff;

  ::v-deep .ant-tabs-tab {
    padding: 12px 0 20px;
    font-size: 16px;
  }

  .header {
    &-title {
      color: #090b12;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &-subtitle {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 15px;

      .copy {
        font-size: 13px;
        margin-left: 8px;
        font-weight: 500;
      }
    }
  }

  .RequirementConfirmation {
    &-title {
      font-size: 15px;
      color: #605f5f;
      margin-bottom: 24px;
      height: 38px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #efefef;
      margin-top: 25px;

      &:before {
        background: #FF6026;
        display: inline-block;
        content: '';
        width: 5px;
        height: 16px;
        margin-right: 8px;
      }

      .title {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #000;
        margin-right: 10px;
      }
    }
  }

  .action-button-area {
    margin-top: 60px;
    text-align: end;

    .ant-btn {
      width: 92px;
      height: 38px;
      line-height: 36px;
      font-weight: 600;
      color: #595959;

      & + .ant-btn {
        margin-left: 8px;
      }
    }

    .ant-btn-primary {
      width: 111px;
      color: #fff;
    }
  }
}

.info-card {
  font-size: 16px;
  font-weight: 500;
  color: #262626;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

::v-deep .less-info {
  .ant-table {
    color: rgba(0, 0, 0, 0.35) !important;
  }

  .total {
    color: rgba(255, 96, 38, 0.65) !important;
  }
}

.info-item {
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 25px;
  box-sizing: border-box;
  // margin-bottom: 24px;
  background-color: #f7f8fa;
  position: relative;

  .option-btn {
    display: flex;
    align-items: center;
    color: #ff6026;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    cursor: pointer;
    position: absolute;
    right: 29px;
    bottom: 15px;

    .icon {
      width: 12px;
      margin-left: 4px;
    }
  }

  &-cover {
    // margin-top: 10px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
  }

  &:last-of-type {
    border-color: transparent;
  }

  .avatar {
    width: 124px;
    height: 124px;
    background-color: #d9d9d9;
    border: 2px solid #fff;
    border-radius: 6px;
    margin-right: 15px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // border-radius: 4px;
    }
  }

  .info-view {
    display: flex;
    flex-direction: column;
    flex: 1.2 0 0;
    min-width: 0;
    // margin-right: 15%;
    text-align: left;
    // padding-top: 10px;

    .main-title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 23px;
      font-weight: 500;
      line-height: 30px;
      display: flex;
      align-items: center;

      img.tag {
        height: 24px;
        margin-left: 10px;
      }
    }

    .tags {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #888888;
    }
  }

  .info-box {
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: left;
    color: #595959;
    text-indent: 4px;
    position: relative;

    &.hidden {
      .text-ellipsis-multi(2);
    }
  }
}

.tags-list {
  display: flex;
  align-items: center;
  grid-gap: 8px 8px;
  flex-wrap: wrap;
  height: 26px;
  margin-top: 8px;
  margin-bottom: 10px;

  &-item {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    color: #666;
    line-height: 22px;
    text-align: center;
    padding: 0 5px;
    font-size: 12px;
    cursor: pointer;
    white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }
}

.view-info {
  font-size: 15px;
  color: #FF6026;
  font-weight: 500;
  margin-left: 25px;
  // position: absolute;
  // left: 429px;
  // top: 37px;
}

.action-part {
  width: 103px;
  height: 38px;
  font-weight: 500;
  position: absolute;
  right: 29px;
  top: 47px;

  .btn-inner {
    display: flex;
    align-items: center;

    .icon {
      width: 18px;
      margin-right: 8px;
    }

    span.text {
      color: #ff6026;
      font-size: 14px;
    }
  }
}

.more-history {
  height: 22px;
  /* 14/常规 */
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  /* or 22px */
  display: flex;
  align-items: center;
  /* 品牌主题色 */
  color: #FF6026;
  margin-top: 22px;
  justify-content: end;
}

.table1 {
  .ant-table-row-cell-break-word {
    padding: 0 !important;
  }
}

::v-deep .table1 td {
  padding: 0 !important;
}

::v-deep .table1 .ant-table-thead > tr > th {
  background-color: #F4F5F7;
}

.td1 {
  border-top: 1px solid #d9d9d9;
  padding: 16px;
  display: flex;
  justify-content: center;
}

.td {
  padding: 16px;
  text-align: center;
}

.td1:first-child {
  border-top: 0px solid #d9d9d9 !important;
  padding: 16px;
}
</style>
